// for localhost
/* Setting the value of the variable `backend` to the string `"http://192.168.1.122:8080"`. */
// export const frontend = "http://localhost/build";
// export const frontend = "http://localhost:3000";
// export const backend = "http://localhost:8080";
// export const backend = "http://192.168.1.122:8080";
//sanskriti
// export const backend = getBackend();
// export const frontend = getFrontend();

function getBackend() {
    let url = window.location.href;
    let backend = "http://192.168.10.132:8090/sanskrtiImagineInquirySys";
    if (url.includes("202.79.34.120")) {
        backend = "http://202.79.34.120:8090/sanskrtiImagineInquirySys";
    }
    return backend;
}

function getFrontend() {
    let url = window.location.href;
    let frontend = "http://192.168.10.132/SanskritiImagineSys";
    if (url.includes("202.79.34.120")) {
        frontend = "http://202.79.34.120/SanskritiImagineSys";
    }
    return frontend;
}

//for http://103.147.226.35/ app.techarttrekkies.com
export const frontend = "https://school.techarttrekkies.com";
export const backend = "https://app.techarttrekkies.com:8443/schoolBackend";

//for intraglobal
// export const frontend = "https://intraglobal.techarttrekkies.com";
// export const backend =
//   "https://intraglobal.techarttrekkies.com:8443/intraglobalImagineInquirySys";

//for ibb
// export const frontend = "https://ibb.techarttrekkies.com";
// export const backend = "https://intraglobal.techarttrekkies.com:8443/indreni";

//for http://103.147.226.35/
// export const frontend = "http://103.147.226.35";
// export const backend =
//   "http://103.147.226.35:8090/intraglobalImagineInquirySys";

//for http://103.147.226.35/ testing
// export const frontend = "http://103.147.226.35/testing";
// export const backend =
//   "http://103.147.226.35:8090/testing";

//for http://103.147.226.36/ ibb.techarttrekkies.com
// export const frontend = "https://ibb.techarttrekkies.com";
// export const backend = "https://app.techarttrekkies.com:8443/indreni";

//for http://103.147.226.36/ nawadixit.techarttrekkies.com
// export const frontend = "https://nawadixit.techarttrekkies.com";
// export const backend = "https://app.techarttrekkies.com:8443/nawadikshit";

//for http://103.147.226.36/ prasiddhamodelschool.com
// export const frontend = "https://prasiddhamodelschool.com";
// export const backend =
//   "https://app.techarttrekkies.com:8443/prasiddhamodelschool";

//for http://103.147.226.36/ souvenir
// export const frontend = "https://souvenir.techarttrekkies.com";
// export const backend = "https://app.techarttrekkies.com:8443/souvenir";

//for http://103.147.226.36/ souvenir
// export const frontend = "https://bmv.techarttrekkies.com";
// export const backend = "https://app.techarttrekkies.com:8443/bmvBackend";

//for http://103.147.226.35/ School instance for master
// export const frontend = "http://learning.cehrd.edu.np";
// export const backend =
//     "https://backend-learning.cehrd.edu.np:8443/learningPortal";
